import { Component, ViewChild } from '@angular/core';
import { WialonApiService } from 'src/service/wialon-api.service';
import { Router } from '@angular/router';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  tireSensor = [];

  order: any = '102200000002';

  title: any = 'TPMS';

  tireCount: any;

  showChart = false;

  unitDetails: any = [];

  selectedUnit = { name: '', id: '', url: '' };

  @ViewChild('chart') chart: ChartComponent;

  public chartOptions: any;

  tireArray: any;

  tireArrayCount: any;

  sessionID;

  constructor(public wialon: WialonApiService, private router: Router) {
    this.tireCount = String('10444000444').substring(2, this.order.length).split('');

    const arrayindex = [
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten'];
    this.tireArray = {
      one: [],
      two: [],
      three: [],
      four: [],
      five: [],
      six: [],
      seven: [],
      eight: [],
      nine: [],
      ten: [],
    };

    arrayindex.forEach((element) => {
      for (let index = 0; index < 4; index++) {
        this.tireArray[element].push('?');
      }
    });
    this.tireArrayCount = this.tireArray;
    this.showChart = true;
    const queryParams = window.location.search;
    const searchParams = new URLSearchParams(queryParams);
    let sid = searchParams.get('sid');

    if (!sid) {
      sid = searchParams.get('?sid');
    }
    this.sessionID = sid;
    if (typeof (Storage) !== 'undefined') {
      // Store
      sessionStorage.setItem('sid', sid);
    }
  }

  ngOnInit() {
    // this.wialon.getSID().subscribe((result: any) => {
    //   const sid = JSON.parse(JSON.stringify(result)).eid;

    this.wialon.getAllUnit(this.sessionID).subscribe((result1: any) => {
      const units = JSON.parse(JSON.stringify(result1)).items;

      units.forEach((element) => {
        if (element.prms && element.prms.tire_number) {
          const url = `https://hst-api.wialon.com${element.uri}?&sid=${this.sessionID}`;
          const unit = {
            name: element.nm, id: element.id, url, netconn: element.netconn,
          };
          this.unitDetails.push(unit);
        }
      });
      this.unitDetails.sort((a: any, b: any) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      if (this.unitDetails.length > 0) {
        this.getSensor(this.unitDetails[0]);
      }
    });
    // });
  }

  getSensor(unit: any) {
    const unitID = unit.id;
    this.selectedUnit = unit;
    this.wialon.getUnitMessage(unitID).subscribe((result1: any) => {
      this.chartOptions = {
        series: [
          {
            name: 'Tire Temperature',
            data: [],
          },
        ],
        chart: {
          height: 350,
          type: 'bar',
        },
        title: {
          text: 'Tire Temperature',
        },
        xaxis: {
          type: 'Tire Number',
          labels: {
            show: true,
          },
          title: {
            text: 'Tire Number',
          },
          categories: [],
        },
      };

      const arrayindex = [
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten'];
      this.tireArray = {
        one: [],
        two: [],
        three: [],
        four: [],
        five: [],
        six: [],
        seven: [],
        eight: [],
        nine: [],
        ten: [],
      };
      arrayindex.forEach((element) => {
        for (let index = 0; index < 4; index++) {
          this.tireArray[element].push('');
        }
      });
      this.tireArrayCount = JSON.parse(JSON.stringify(this.tireArray));
      const result = JSON.parse(JSON.stringify(result1));
      this.tireCount = String(result.data.unitData[0].custom ? result.data.unitData[0].custom : '10224002044').substring(2, this.order.length).split('');
      this.tireSensor = [];

      result.data.unitData.forEach((element: any) => {
        let rowCounter: number = 0;
        for (let index = 0; index < this.tireCount.length; index++) {
          for (let inc = 0; inc < Number(this.tireCount[index]); inc++) {
            if (Number(this.tireCount[index]) === 4) {
              this.tireArrayCount[arrayindex[index]][inc] = rowCounter + inc + 1;
            } else {
              this.tireArrayCount[arrayindex[index]][inc == 0 ? 0 : 3] = rowCounter + inc + 1;
            }
          }
          rowCounter += Number(this.tireCount[index]);
        }
      });

      result.data.unitData.forEach((element: any) => {
        let rowCounter: number = 0;
        for (let index = 0; index < this.tireCount.length; index++) {
          rowCounter += Number(this.tireCount[index]);

          if (rowCounter >= Number(element.tiresensor['Tire Number'])) {
            if (Number(this.tireCount[index]) === 4) {
              this.tireArray[arrayindex[index]][(Number(element.tiresensor['Tire Number']) + 3) - rowCounter] = `${Number(element.tiresensor['Tire Temperature'])}°${element.tiresensor['Pressure Scale']}`;
            } else {
              const data = (Number(element.tiresensor['Tire Number']) + 1) - rowCounter;
              this.tireArray[arrayindex[index]][data === 0 ? 0 : 3] = `${Number(element.tiresensor['Tire Temperature'])}°${element.tiresensor['Pressure Scale']}`;
            }
            rowCounter = 0;
            break;
          }
        }

        this.tireSensor.push(element.tiresensor);
        this.tireSensor.sort((a: number, b: number) => {
          if (a['Tire Number'] < b['Tire Number']) { return -1; }
          if (a['Tire Number'] > b['Tire Number']) { return 1; }
          return 0;
        });
      });
      this.showChart = true;
    });
  }
}
