import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class WialonApiService {
  hostUrl: any = 'https://wialonapp.herokuapp.com/https://hst-api.wialon.com/wialon/ajax.html?';

  constructor(private http: HttpClient) { }

  public getAllUnit(res) {
    const getunit: any = `${this.hostUrl}svc=core/search_items&sid=${res}&params={"spec":{"itemsType":"avl_unit",
      "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
    return this.http.post(getunit, {}, { });
  }

  public getAllUnitResource(res) {
    const getunit: any = `${this.hostUrl}svc=core/search_items&sid=${res}&params={"spec":{"itemsType":"avl_resource",
    "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;

    return this.http.post(getunit, {}, { });
  }

  public getSID() {
    if (typeof (Storage) !== 'undefined') {
      // Store
      // return Promise.resolve('09652c5fbbd1b1cb763e382213f74200');
      return sessionStorage.getItem('sid');
    }
    return null;

    // const jwt = '"a4fd021e27166bae068090746adc4c2bDE34EB14D24DE27641AAE6C96D366FB3A26DECCD"';
    // const siteURL: any = `${this.hostUrl}svc=token/login&params={"token":${jwt}}`;
    // return this.http.post(siteURL, {}, { });
  }

  public getUnitMessage(unitID:string) {
    const getunit: any = `https://i0sobfysb0.execute-api.ap-south-1.amazonaws.com/novatpms/api/gettpmsdata?unit=${unitID}`;

    return this.http.get(getunit, {});
  }
}
